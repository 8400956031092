
body{
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight:300;
  color:#454545;
}

h1, h2, h3, h4{
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight:700;
}

h1{
  font-weight: 900;
  letter-spacing: -0.1rem;
}

.btn-primary{
  background-color:#9a3234;
  border-color:#9a3234;

  &:hover{
    background-color:#662021;
    border-color:#662021;
  }
}

.hilite-01{
  color:#c52e2e;
}

.hilite-02{
  color:#ce7006;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



