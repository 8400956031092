footer{
    background-color:#222222;
    padding:20px 0px;
    text-align: center;
    color: #b9b9b9;
    font-size:0.8rem;

    span{
        color: #ffffff;
    }
}