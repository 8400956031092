body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  background-color: #222222;
  padding: 20px 0px;
  text-align: center;
  color: #b9b9b9;
  font-size: 0.8rem; }
  footer span {
    color: #ffffff; }

body {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #454545; }

h1, h2, h3, h4 {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 700; }

h1 {
  font-weight: 900;
  letter-spacing: -0.1rem; }

.btn-primary {
  background-color: #9a3234;
  border-color: #9a3234; }
  .btn-primary:hover {
    background-color: #662021;
    border-color: #662021; }

.hilite-01 {
  color: #c52e2e; }

.hilite-02 {
  color: #ce7006; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

